


















































import Component from "vue-class-component";
import { PageBase } from "@/core/models/shared";
import { RegistroOperacionalService } from "@/core/services/operacional";
import { EmpresaService } from "@/core/services/geral";
import { Empresa } from "@/core/models/geral";
import { AlertSimpleErr } from "@/core/services/shared/AlertService";


@Component
export default class RelatorioAtracacao extends PageBase{
    service = new RegistroOperacionalService();
    itens: any [] = [];
    empresaService = new EmpresaService();
    empresa: Empresa = new Empresa();
    overlay: boolean = false;

    filtro: any = {
        numero: null,
        propostaId: null,
        clienteId: null,
        embarcacaoId: null,
        situacaoId: null,
        atracacao: null,
        responsavelId: null,
    };

    mounted() {
        this.filtro.numero = this.$route.query.numero;
        this.filtro.propostaId = this.$route.query.propostaId;
        this.filtro.clienteId = this.$route.query.embarcacaoId;
        this.filtro.embarcacaoId = this.$route.query.embarcacaoId;
        this.filtro.situacaoId = this.$route.query.situacaoId;
        this.filtro.atracacao = this.$route.query.atracacao;
        this.filtro.responsavelId = this.$route.query.responsavelId;
        this.Carregar();
    }

    Carregar(){
        this.overlay = true;

        this.empresaService.ObterPorId(this.app.empresaId, "Endereco.Municipio.Estado").then(
            res=>{
                this.empresa = res.data;
            },
            err=> AlertSimpleErr("Aviso!", err)
        )

        this.service.RelatorioAtracacao(this.filtro.numero,this.filtro.propostaId,this.filtro.clienteId,this.filtro.embarcacaoId,this.filtro.situacaoId,this.filtro.atracacao,this.filtro.responsavelId).then(
            res => {
                this.itens = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.overlay = false;
        })
    }
}
